import { createGlobalStyle } from "styled-components";
import AvenirLTStdBlack from "../fonts/AvenirLTStd-Black.otf";
import AvenirLTStdBook from "../fonts/AvenirLTStd-Book.otf";
import AvenirLTStdMedium from "../fonts/AvenirLTStd-Medium.otf";

export const colors = {
  white: "#fff",
  black: "#000",
  darkGray: "#1a1c20",
  deepDarkGray: "#404040",
  blueShade1: "#27b5e2",
  blueShade2: "#6fadc6",
  blueShade3: "#d1e1e9",
  blueShade4: "#3b7d9c",
  redShade: "#bf3030",
};

export const lightTheme = {
  colors: {
    background: colors.white,
    menuBackground: colors.white,
    textDark: colors.black,
    textMain: colors.blueShade2,
    textSecondary: colors.deepDarkGray,
    textCustom: colors.blueShade1,
    textDanger: colors.redShade,
    textLink: colors.blueShade4,
    buttonLink: colors.blueShade1,
  },
};

export const GlobalStyles = createGlobalStyle`
  :root {
    --site-max-width: 1200px;
  }

  @font-face {
    font-family: "Avenir LTStd-Book";
    src: local("Avenir LTStd-Book"), local("Avenir LTStd-Book"), url(${AvenirLTStdBook}) format("opentype");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "Avenir LTStd-Medium";
    src: local("Avenir LTStd-Medium"), local("Avenir LTStd-Medium"), url(${AvenirLTStdMedium}) format("opentype");
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Avenir LTStd-Black";
    src: local("Avenir LTStd-Black"), local("Avenir LTStd-Black"), url(${AvenirLTStdBlack}) format("opentype");
    font-weight: 900;
    font-style: normal;
  }

  html {
    scroll-padding-top: 100px; /* height of sticky header plus margin */
  }
  
  body {
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.textSecondary};
    font-family: "Avenir LTStd-Book", Arial, Helvetica, sans-serif;
    margin: 0;
    /*padding: 0 20px 80px 20px;*/
    transition: all 0.5s ease;
  }
  
  main {
    margin: 0;
    width: 100%;
    padding-top: 80px;

    @media screen and (min-width: 769px) {
        text-align: left;
    }
  }

  #start, #about, #contact, #referenssit, #ui, #quote-section {
    >div {
      margin: 0 auto;
      max-width: 960px;
    }
  }

  #start {

    h2 {
      font-size: 1.5em;
      font-family: Avenir LTStd-Medium;
      font-weight: 500;
      max-width: 960px;

      @media screen and (min-width: 768px) {
        font-size: 2.0em;
      }
    }
  }

  #how {
    max-width: 960px;
    margin: 0 auto;

    p {
      padding: 0 10px;
    }

    @media screen and (min-width: 769px) {
      p {
         padding: 0;
      }
    }
  }

  #how, #kayttoonotto, #contact {

     @media screen and (max-width: 768px) {
        img {
          display: none;
        }
    }
    
  }

  #demo, #kayttoonotto, #testaus, #tilaus  {
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    padding: 0;
    gap: 0;

    p {
      font-size: 1em;
      padding: 0 10px;
    }
   
    @media screen and (min-width: 769px) {
      text-align: left;

      p {
         padding: 0;
      }
    }
  }

  #referenssit {
    margin-bottom: 0px;

    h4 {
      font-family: Avenir LTStd-Medium;
      font-size: 1.6rem;
      font-weight: 500;
    }

    h2 {
      font-size: 1.8rem;
    }

    p > img {
      margin: 0;
      max-width: 150px;
    }
  }

  #contact {
    margin-bottom: 0;
    padding: 50px 0;

    p {
      margin-bottom: 30px;
    }

    h2, h4 {
      color: white;
    }

    div {
      grid-template-columns: 1fr;
      gap: 10px;
      text-align: center;

      @media screen and (min-width: 769px) {
        grid-template-columns: auto 1fr;
        text-align: left;
      }
    }
  }

  #about {
    h2 {
      max-width: 500px;
      margin: 0 auto;
      padding: 10px 20px;
    }
  }

  section {
    padding: 0px;
    margin-bottom: 50px;
    text-align: center;

    div h2 {
      font-size: 1.5em;
      margin: 0;
    }

    @media screen and (min-width: 769px) {
      padding: 20px 10px;
    }
  }
  
  h1, h2, h3, h4, h5, h6 {
    color: ${props => props.theme.colors.textDark};
    font-family: "Avenir LTStd-Black", Arial, Helvetica, sans-serif;
    transition: all 0.5s ease;
    letter-spacing: 1px;
    margin-bottom: 20px;
    font-weight: 900;
  }

  h1 {
    font-size: 2.0em;
    font-family: "Avenir LTStd-Black", Arial, Helvetica, sans-serif;

    @media screen and (min-width: 768px) {
        font-size: 3.0em;
      }
  }
  
  h2 {
    font-size: 2em;
    letter-spacing: 1.8px;
    line-height: 1.2em;
    margin: 30px 0;
    
  }
  
  h3 {
    font-size: 1.4rem;
    letter-spacing: 1.5px;
    margin: 30px 0 0 0;
  }

  h4 {
    font-size: 1.2rem;
  }
  
  p {
    font-size: 1rem;
    line-height: 1.4;
    
    @media screen and (min-width: 768px) {
      line-height: 1.5;
    }
  }
  
  li {
    font-size: 1.1rem;
    line-height: 1.5;
  }
  
  hr {
    margin-top: 30px;
    border: none;
    border-top: 1px solid #ccc;
  }
  
  a {
    line-height: 1.5;
    color: ${props => props.theme.colors.textLink};
  }
  
  a:link {
    text-decoration: none;
  }
  
  a:visited {
    text-decoration: none;
  }

  img {
    max-width: 300px;
    max-height: 200px;

    @media screen and (min-width: 769px) {
        max-height: 400px;
    }
  }

  #calendar {
    padding: 20px;
    div {
      margin: 0;
    }
    h2 {
      color: #ffffff;
      font-size: 2rem;
    }
    p {
      font-size: 1em;
    }
    a {
      background: #ffffff;
      color: ${props => props.theme.colors.buttonLink};
      border: 2px solid #ffffff;
      border-radius: .3em;
      padding: 10px 15px;
      font-weight: normal;
      font-size: 1.25em;

      &:hover {
        opacity: 0.9;
      }
    }
  }

  .hire-robot-btn {
    padding: 10px 30px;
    font-size: 1.5em;
    border-radius: 6px;
    border: none;
    margin-top: 30px;
    width: 90%;
    margin: 20px;

    :focus { 
      outline: 0 !important;
      box-shadow: none !important; 
    }

    @media screen and (min-width: 769px) {
      padding: 16px 80px;
      width: 500px;
    }
  }

  .quote-section {
    background: #27B5E2;
    color: white;
    margin-bottom: 30px;

    blockquote {
      font-size: 1.2rem;
    }

    p {
      font-size: 1rem;
    }

    div {
      max-width: 960px;
      margin: 10px auto;
    }
  }
`;
