import React, { useState } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { HeaderWrapper, Branding } from "./Header.styles";
import Hamburger from "../Hamburger";
import Menu from "../Menu";
import MobileMenu from "../MobileMenu";
import { useNavigationQuery } from "../../hooks/useNavigationQuery";

const Header = ({ siteTitle }) => {
  const navQuery = useNavigationQuery();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <HeaderWrapper>
      <Branding>
        <Link to={"/"}>{siteTitle}</Link>
      </Branding>

      <Menu items={navQuery.body} />

      <Hamburger menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <MobileMenu
        menuOpen={menuOpen}
        items={navQuery.body}
        setMenuOpen={setMenuOpen}
      />
    </HeaderWrapper>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

export default Header;
