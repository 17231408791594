import React from "react";
import { Nav } from "./Menu.styles";
import { Link } from "gatsby";

const Menu = ({ items }) => (
  <Nav>
    {items.map(menu => (
      <li key={menu.primary.menu_id}>
        <Link to={menu.primary.menu_link}>{menu.primary.menu_name}</Link>
      </li>
    ))}
  </Nav>
);

export default Menu;
