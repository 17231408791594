import styled, { createGlobalStyle } from "styled-components";

export const OverrideGlobalStyle = createGlobalStyle`
  body {
    overflow: ${props => (props.menuOpen ? "hidden" : "auto")};
  }
`;

export const MenuWrapper = styled.div`
  display: block;
  position: fixed;
  top: ${props => (props.menuOpen ? "-10px" : "-300px")};
  width: 100%;
  left: 0;
  padding: 0;
  transition: opacity 0.4s ease;
  z-index: 10;
  box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.3);
  box-sizing: border-box;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const Nav = styled.ul`
  position: absolute;
  width: 100%;
  top: 80px;
  list-style-type: none;
  padding: 10px;
  font-family: "Lato", Arial, Helvetica, sans-serif;
  background: ${props => props.theme.colors.menuBackground};
  text-align: center;

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.textLink};
  }

  li {
    transition: opacity 0.5s ease;
    cursor: pointer;
    margin: 10px 0;
    font-size: 1.5rem;
    border-bottom: 1px solid #eee;
    line-height: 1.8em;

    .active {
      font-weight: 800;
    }

    :hover {
      transform: scale(1.1);
    }
  }

  @media screen and (min-width: 768px) {
    display: block;
  }
`;
