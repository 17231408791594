import React from "react";
import { MenuWrapper, Nav, OverrideGlobalStyle } from "./MobileMenu.styles";
import { Link } from "gatsby";

const MobileMenu = ({ menuOpen, items, setMenuOpen }) => (
  <>
    <OverrideGlobalStyle menuOpen={menuOpen} />
    <MenuWrapper menuOpen={menuOpen}>
      <Nav>
        {items.map(item => (
          <li key={item.primary.menu_id}>
            <Link to={item.primary.menu_link} onClick={e => setMenuOpen(false)}>
              {item.primary.menu_name}
            </Link>
          </li>
        ))}
      </Nav>
    </MenuWrapper>
  </>
);

export default MobileMenu;
