import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Header from "../Header";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import { GlobalStyles, lightTheme } from "../../styles/GlobalStyles";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          branding
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyles />
      <Header siteTitle={data.site.siteMetadata.branding} />
      <main>{children}</main>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
