import styled from "styled-components";

export const HeaderWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  left: 0;
  height: 90px;
  margin: 0;
  z-index: 100;
  background: white;
  border-bottom: 1px solid #ccc;
`;

export const Branding = styled.div`
  margin: auto 0;
  min-width: 100px;

  a {
    font-size: 28px;
    color: #000;
    font-family: Avenir LTStd-Black;
    font-weight: 900;
    text-decoration: none;
  }
`;
