import { useStaticQuery, graphql } from "gatsby";

export const useNavigationQuery = () => {
  const data = useStaticQuery(graphql`
    query NavigationQuery {
      prismic {
        allNavigations {
          edges {
            node {
              _meta {
                lang
                id
              }
              body {
                ... on PRISMIC_NavigationBodyMenu {
                  primary {
                    menu_id
                    menu_link
                    menu_name
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return data.prismic.allNavigations.edges[0].node;
};
